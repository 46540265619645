import React from "react";
import LoadingDots from "./LoadingDots";

export interface LoadingComponentProps {
    lastStage: string,
    nextStage: string,
    isLoading: boolean,
    isError: boolean,
    errorMessage: string,
    completedSteps: string[]
}

export const LoadingComponent: React.FC<LoadingComponentProps> = ({lastStage, nextStage, isLoading, isError, errorMessage, completedSteps  }) => {
    return (

        <div className={"loading-component"}>
            {(isError || isLoading) && (
                <div className={"loading-inner"}>
                    {isError && (
                        <div className="loading-component__content">
                            <h2>Error</h2>
                            <p>{errorMessage}</p>
                        </div>)}

                    {!isError && isLoading && (
                        <div className="loading-component__content">
                            <h2>Please Wait...</h2>
                            {completedSteps.length > 0 && (
                                <div className={"completed-steps"}>
                                    <ul>
                                        {completedSteps.map((step, index) => (
                                            <li key={index}>{step}...Done</li>
                                        ))}
                                        <li><LoadingDots stageName={nextStage}/></li>
                                    </ul>
                                </div>
                            )}

                        </div>
                    )}
                </div>)
            }
        </div>

    )
}