import React from 'react';
import Select, { SingleValue, ActionMeta } from 'react-select';

// Define the shape of your option objects
export interface IndustryOption {
    value: string;
    label: string;
}

// Props type
interface IndustrySelectProps {
    onIndustryChange: (selectedOption: SingleValue<IndustryOption>) => void;
    industryOptions: IndustryOption[]; // Directly use an array of IndustryOption
    selectedIndustry: SingleValue<IndustryOption>;
}

const SelectIndustry: React.FC<IndustrySelectProps> = ({
                                                     onIndustryChange,
                                                     industryOptions,
                                                     selectedIndustry,
                                                 }) => {
    // Handler for when an option is selected
    const handleSelectChange = (selectedOption: SingleValue<IndustryOption>, actionMeta: ActionMeta<IndustryOption>) => {
        onIndustryChange(selectedOption);
    };

    return (
        <div className="form-element">
            <label htmlFor="industry">What industry does your business operate in?</label>
            <Select<IndustryOption>
                id="industry"
                name="industry"
                value={selectedIndustry}
                onChange={handleSelectChange}
                options={industryOptions}
                className="basic-single"
                classNamePrefix="select"
            />
        </div>
    );
};

export default SelectIndustry;