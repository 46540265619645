import React, {useEffect, useState} from 'react';
import './App.css';
import UserInputForm, {OnboardingFormData} from "./UserInputForm.module";
import ImageGrid, {Logo} from "./ImageGrid.component";
import {socket} from "./socket";

import { getFingerprint, getFingerprintData } from "@thumbmarkjs/thumbmarkjs";
import {LoadingComponent} from "./loading.component";

function App() {

    const [logos, setLogos] = useState<Logo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [lastStage, setLastStage] = useState('');
    const [nextStage, setNextStage] = useState('');
    const [identified, setIdentified] = useState(false);
    const [identifying, setIdentifying] = useState(false);
    const [socketConnected, setSocketConnected] = useState(false);
    const [userId, setUserId] = useState('');
    const [selectedLogo, setSelectedLogo] = useState<Logo | null>(null);
    const [completedSteps, setCompletedSteps] = useState<string[]>([]);


    useEffect(() => {
        socket.on('connect', () => {
            if (!socketConnected){
                setSocketConnected(true);
                identify();
            }
        })

        // Listen for progress updates
        socket.on('progress', (data) => {
            console.log("Progress: ", data);
            setCompletedSteps((currentSteps) => [...currentSteps, data.completedStep]);
            setNextStage(data.nextStep);
        });

        socket.on('moderation_rejected', (message) => {
           console.log("Moderation rejected", message);
           setErrorMessage("Moderation rejected: " + message);
        });

        socket.on('registered', (message) => {
            setIdentified(true);
            setIdentifying(false);
            setUserId(message.userId);
            console.log("Registered: ", message);
        });

        // Cleanup on component unmount
        return () => {
            socket.off('progress');
            socket.off('moderation_rejected');
            socket.off('connect');
            socket.off('registered');
        };
    }, []);

    const addStep = (step: string) => {
        console.log("Add step", step);
        setCompletedSteps([...completedSteps, step]);
        console.log("Completed steps", completedSteps);
    }

    const identify = async () => {
        const fp = await getFingerprint();
        const fpData = await getFingerprintData();
        if (!identified && !identifying) {
            setIdentifying(true);
            console.log("Registerering", fp);
            socket.emit('register', {fingerprint: fp, fpData: fpData});
        }
    }

    const fetchImages = (formData: OnboardingFormData) => {
        console.log("User id", userId);
        try {
            fetch('https://logo-api.apps.automatec.com.au/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    companyName: formData.companyName,
                    description: formData.description,
                    industry: formData.industry?.value,
                    userId
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.logos && data.logos.length) {
                        console.log('Success:', data);
                        setLogos(data.logos);
                    } else {
                        console.error("Error, response contained no logo", data);
                    }
                })
                .catch((error) => {
                        console.error('Error:', error);
                        setIsError(true);
                        setErrorMessage(error.message);
                    }
                )
                .finally(() => {
                    setIsLoading(false)
                    // setLoadingMessage('');
                });

        } catch (err){
            console.error(err);
            setIsError(true);
            setErrorMessage("An error occurred while generating logos. Please try again later.");
        }

    };

  const handleFormSubmit = (formData: OnboardingFormData) => {
    console.log(formData);
    setIsLoading(true);
    setHasSubmitted(true);
    fetchImages(formData);
    }

    const handleSelectFavourite = (logo: Logo) => {
        setSelectedLogo(logo);
    }


  return (
    <div className="logo-generator">

        <div className={"app-header"}>
            <h1>Generate a Free Business Logo</h1>
        </div>
        <div className={"app-body"}>
            {selectedLogo ?
                <div className={"container"}>
                    <div className="selected-logo">
                        <img src={selectedLogo.biggerUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                </div>
                :

                <div className="container">

                    {hasSubmitted ? null :
                        <div className="user-input">
                            <UserInputForm onSubmit={handleFormSubmit} />
                        </div>
                    }
                    {hasSubmitted && !isLoading ?
                        <div className="logo-display">
                            <ImageGrid logos={logos} selectFavourite={handleSelectFavourite} />
                        </div>
                        : null}
                </div>
            }

            <LoadingComponent completedSteps={completedSteps} lastStage={lastStage} nextStage={nextStage} isLoading={isLoading} isError={isError} errorMessage={errorMessage}/>
        </div>


    </div>
  );
}

export default App;
