import React, { useState, useEffect } from 'react';

interface LoadingDotsProps {
    stageName: string;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({stageName}) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
        }, 500); // Change dot every 500 milliseconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            {stageName}{dots.split('').map((dot, index) => (
            <span key={index} className="dot">{dot}</span>
        ))}
        </div>
    );
};

export default LoadingDots;