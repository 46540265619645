import React from 'react';

export interface Logo {
    url: string;
    biggerUrl: string;
    ref: string;
}

interface ImageGridProps {
    logos: Logo[];
    selectFavourite: (selectedLogo: Logo) => void;
}

export const ImageGrid: React.FC<ImageGridProps> = ({ logos , selectFavourite}) => {
    const handleSelectFavourite = (logo: Logo) => {
        // Call the selectFavourite function passed as a prop with the selected logo
        selectFavourite(logo);
    };

    return (
        <div>
        <h3>Your free logo suggestions</h3>
            <div className="image-grid" style={{ display: 'grid', gap: '30px' }}>
                {logos.map((logo) => (
                    <div key={logo.ref} style={{ width: '100%', paddingBottom: '100%', position: 'relative' }} onClick={() => handleSelectFavourite(logo)}>
                        <img src={logo.url} alt="" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageGrid;