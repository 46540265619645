import React, { useState, ChangeEvent, FormEvent } from 'react';
import SelectIndustry, {IndustryOption} from "./AutoCompleteInput.component";
import {SingleValue} from "react-select";


// Define the props type for the component
interface UserInputFormProps {
    onSubmit: (formData: OnboardingFormData) => void; // Function to call on form submission
}

// Define the OnboardingFormData type
export interface OnboardingFormData {
    companyName: string;
    description: string;
    industry: SingleValue<IndustryOption> | null;
}

const industryOptions: IndustryOption[] = [
    { value: 'aerospace', label: 'Aerospace' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'banking', label: 'Banking' },
    { value: 'biotechnology', label: 'Biotechnology' },
    { value: 'chemicals', label: 'Chemicals' },
    { value: 'construction', label: 'Construction' },
    { value: 'consulting', label: 'Consulting' },
    { value: 'consumer_goods', label: 'Consumer Goods' },
    { value: 'cybersecurity', label: 'Cybersecurity' },
    { value: 'design', label: 'Design' },
    { value: 'ecommerce', label: 'E-commerce' },
    { value: 'education', label: 'Education' },
    { value: 'education_technology', label: 'Education Technology' },
    { value: 'energy', label: 'Energy' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'environmental', label: 'Environmental' },
    { value: 'events_services', label: 'Events Services' },
    { value: 'fashion', label: 'Fashion' },
    { value: 'financial_services', label: 'Financial Services' },
    { value: 'food_beverage', label: 'Food & Beverage' },
    { value: 'government', label: 'Government' },
    { value: 'government_relations', label: 'Government Relations' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'hospitality', label: 'Hospitality' },
    { value: 'information_technology', label: 'Information Technology' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'legal_services', label: 'Legal Services' },
    { value: 'logistics_supply_chain', label: 'Logistics & Supply Chain' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'maritime', label: 'Maritime' },
    { value: 'marketing_advertising', label: 'Marketing & Advertising' },
    { value: 'media', label: 'Media' },
    { value: 'mining', label: 'Mining' },
    { value: 'nanotechnology', label: 'Nanotechnology' },
    { value: 'nonprofit', label: 'Nonprofit' },
    { value: 'outsourcing_offshoring', label: 'Outsourcing/Offshoring' },
    { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
    { value: 'real_estate', label: 'Real Estate' },
    { value: 'renewable_energy', label: 'Renewable Energy' },
    { value: 'research', label: 'Research' },
    { value: 'retail', label: 'Retail' },
    { value: 'robotics', label: 'Robotics' },
    { value: 'technology', label: 'Technology' },
    { value: 'telecommunications', label: 'Telecommunications' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'utilities', label: 'Utilities' },
    { value: 'virtual_reality', label: 'Virtual Reality' },
    { value: 'wellness_fitness', label: 'Wellness & Fitness' },
    { value: 'wholesale', label: 'Wholesale' },
    { value: 'other', label: 'Other'}
];

const UserInputForm: React.FC<UserInputFormProps> = ({ onSubmit }) => {
    const [formData, setFormData] = useState<OnboardingFormData>({ companyName: 'Hercules Morse with Black Bean Sauce', description: 'Combination Chinese Restaurant and Dog Grooming Salon', industry: null });


    // Handle input change
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    const handleIndustryChange = (selectedOption: SingleValue<IndustryOption>) => {
        // Update your form state based on the selected option
        setFormData(prevFormData => ({
            ...prevFormData,
            industry: selectedOption,
        }));
    };

    // Handle form submission
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(formData); // Pass the form data back to the parent component
        // Optionally reset the form
        setFormData({ companyName: '', description: '', industry: null });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-element">
                <label htmlFor="companyName">What is the name of your business?</label>
                <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-element">
                <label htmlFor="description">Describe your Business</label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleTextareaChange}
                    required
                />
            </div>
            <div className="form-element">

                <SelectIndustry
                    selectedIndustry={formData.industry}
                    onIndustryChange={handleIndustryChange}
                    industryOptions={industryOptions}
                />


            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default UserInputForm;